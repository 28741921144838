import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonCheckbox, IonContent, IonFooter, IonItem, ModalController } from '@ionic/angular/standalone';
import { App, People } from '@shared/api';
import { PrivacySettings } from '../../models/privacy-settings.model';
import { GlobalSettingsState } from '@core/root-store/store/global-settings/models/global-settings-state.model';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { ModalComponent } from '@common/components/modal/modal.component';
import { ScrollbarDirective } from '@common/directives/scroll-bar/scroll-bar.directive';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
    selector: 'cc-privacy-settings-dialog',
    templateUrl: './privacy-settings-dialog.component.html',
    styleUrls: ['./privacy-settings-dialog.component.scss'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RxReactiveFormsModule,
        ModalComponent,
        ScrollbarDirective,
        NgFor,
        NgIf,
        TranslateModule,
        IonContent,
        IonItem,
        IonCheckbox,
        IonFooter,
        IonButton
    ]
})
export class PrivacySettingsDialogComponent implements OnInit {
    @Input()
    public app: App;

    @Input()
    public loggedInUser: People;

    @Input()
    public useDefaults: boolean = false;

    public privacySettingsForm: UntypedFormGroup;
    public items: { control: string; props: PrivacyItemProps; show: boolean }[] = [];

    constructor(
        private formBuilder: UntypedFormBuilder,
        private modalController: ModalController,
        private appCoreFacadeService: AppCoreFacadeService
    ) {}

    public ngOnInit(): void {
        this.appCoreFacadeService
            .getGlobalSettings()
            .pipe(untilDestroyed(this))
            .subscribe((settings) => {
                this.createForm(this.app, this.loggedInUser, settings);
            });
    }

    public onSubmit(): void {
        const response = this.privacySettingsForm.value as PrivacySettings;
        this.modalController.dismiss(response);
    }

    private createForm(app: App, user: People, settings: GlobalSettingsState): void {
        const values = {
            profileVisible: this.useDefaults ? true : user.show_in_people_modules,
            chatEnabled: this.useDefaults ? true : user.chat_enabled,
            acceptsVideoCalls: this.useDefaults ? true : user.accepts_video_calls,
            meetingBookingEnabled: this.useDefaults ? true : user.meeting_booking_enabled,
            businessCardsEnabled: this.useDefaults ? true : user.business_cards_enabled
        };

        const controls = {};

        Object.entries(values).forEach(([key, defaultValue]) => {
            // Form control
            controls[key] = this.formBuilder.control(defaultValue);
            // Item to be used in template
            this.items.push({
                control: key,
                props: this.getPropsForSetting(key),
                show: this.showControl(key, app, user, settings)
            });
        });
        this.privacySettingsForm = this.formBuilder.group(controls);
    }

    private getPropsForSetting(setting: string): PrivacyItemProps {
        const settingsDictionary: Record<keyof PrivacySettings, PrivacyItemProps> = {
            acceptsVideoCalls: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_AVAILABLE_FOR_VIDEO_MEETINGS',
                default: 'Available for Video Meetings',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_AVAILABLE_FOR_VIDEO_MEETINGS'
            },
            chatEnabled: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_CHAT',
                default: 'Chat Messaging',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_CHAT'
            },
            profileVisible: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_PROFILE_VISIBLE',
                default: 'Profile Visible in App',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_PROFILE_VISIBLE'
            },
            meetingBookingEnabled: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_MEETING_BOOKING',
                default: 'Available for Meeting Booking',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_MEETING_BOOKING'
            },
            businessCardsEnabled: {
                label: 'PRIVACY_SETTINGS_DIALOG_LABEL_BUSINESS_CARDS',
                default: 'Available for Business Card exchanges',
                sublabel: 'PRIVACY_SETTINGS_DIALOG_SUBLABEL_BUSINESS_CARDS'
            }
        };

        return settingsDictionary[setting];
    }

    private showControl(key: string, app: App, user: People, settings: GlobalSettingsState): boolean {
        switch (key) {
            case 'chatEnabled':
                return app.can_chat;
            case 'acceptsVideoCalls':
                return app.enable_delegate_video_calls;
            case 'meetingBookingEnabled':
                return (
                    app.mbookings_enabled &&
                    this.hasPeopleGroup({
                        featurePeopleGroups: settings.meetingBookingConfig?.allowed_peoplegroups,
                        userPeopleGroups: user?.peoplegroups
                    })
                );
            case 'businessCardsEnabled':
                return (
                    app.business_cards_enabled &&
                    this.hasPeopleGroup({
                        featurePeopleGroups: settings.businessCardsSettings?.people_groups,
                        userPeopleGroups: user?.peoplegroups
                    })
                );
        }

        return true;
    }

    private hasPeopleGroup(params: { featurePeopleGroups: string[]; userPeopleGroups: string[] }): boolean {
        const { featurePeopleGroups, userPeopleGroups } = params;
        if (
            !featurePeopleGroups ||
            !userPeopleGroups ||
            (featurePeopleGroups.length === 0 && userPeopleGroups.length === 0) ||
            featurePeopleGroups.length === 0
        ) {
            return true;
        }

        return featurePeopleGroups.some((group) => userPeopleGroups.includes(group));
    }
}

interface PrivacyItemProps {
    label: string;
    default: string;
    sublabel?: string;
}
