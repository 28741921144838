import { Injectable } from '@angular/core';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { DialogIds } from '@core/modal/constants/dialog-ids.constant';
import { AuthenticationActions } from '@core/root-store/store/authentication/actions/authentication.actions';
import { CookieNoticeDialogComponent } from '@core/tos/components/cookie-notice-dialog/cookie-notice.component';
import { PrivacySettingsDialogComponent } from '@core/tos/components/privacy-settings-dialog/privacy-settings-dialog.component';
import { TosDialogComponent } from '@core/tos/components/tos-dialog/tos-dialog.component';
import { PrivacySettings } from '@core/tos/models/privacy-settings.model';
import { ModalController } from '@ionic/angular/standalone';
import { App, People } from '@shared/api';
import { FocusService } from 'src/app/core/a11y/services/focus.service';
import { TosApiService } from '../tos-api/tos-api.service';

@Injectable({
    providedIn: 'root'
})
export class TosDialogService {
    constructor(
        private modalController: ModalController,
        private tosApiService: TosApiService,
        private authenticationFacadeService: AuthenticationFacadeService,
        private focusService: FocusService
    ) {}

    async showTosDialog(appUrl: string): Promise<boolean> {
        const modal = await this.modalController.create({
            component: TosDialogComponent,
            componentProps: { appUrl },
            backdropDismiss: false,
            id: DialogIds.TosDialog
        });
        await this.focusService.handleIonicModalFocus(modal);
        await modal.present();
        return modal.onWillDismiss().then((r) => r.data);
    }

    async showPrivacySettingsDialog(
        app: App,
        loggedInUser: People,
        useDefaults: boolean = false
    ): Promise<PrivacySettings> {
        const modal = await this.modalController.create({
            component: PrivacySettingsDialogComponent,
            componentProps: { app, loggedInUser, useDefaults },
            backdropDismiss: false
        });
        await this.focusService.handleIonicModalFocus(modal);
        await modal.present();
        return modal.onWillDismiss().then((r) => r.data);
    }

    async showCookieNotice(): Promise<boolean> {
        const modal = await this.modalController.create({
            component: CookieNoticeDialogComponent,
            backdropDismiss: false
        });
        await this.focusService.handleIonicModalFocus(modal);
        await modal.present();
        return modal.onWillDismiss().then((r) => r.data);
    }

    async checkAndShowDialog(app: App, loggedInUser: People): Promise<boolean> {
        const hasAccepted = await this.tosApiService.hasAccepted(app.url_name).toPromise();
        // Already accepted so don't show again
        if (hasAccepted) {
            return true;
        }
        const tosDialogResult = await this.showTosDialog(app.url_name);
        // Return false if they didn't accept
        if (!tosDialogResult) {
            return false;
        }
        // Show privacy settings if they did accept
        const privacySettingsDialogResult = await this.showPrivacySettingsDialog(app, loggedInUser);
        // The dialog was cancelled
        if (!privacySettingsDialogResult) {
            return true;
        }
        const patch = this.getProfileUpdate(privacySettingsDialogResult);
        this.authenticationFacadeService.dispatch(AuthenticationActions.patchAuthenticatedPerson({ patch }));
        return true;
    }

    getProfileUpdate(privacySettings: PrivacySettings): Partial<People> {
        return {
            accepts_video_calls: privacySettings.acceptsVideoCalls,
            chat_enabled: privacySettings.chatEnabled,
            show_in_people_modules: privacySettings.profileVisible,
            meeting_booking_enabled: privacySettings.meetingBookingEnabled,
            business_cards_enabled: privacySettings.businessCardsEnabled
        };
    }
}
