@if (privacySettingsForm) {
    <form [formGroup]="privacySettingsForm" (ngSubmit)="onSubmit()">
        <cc-modal header="PRIVACY_SETTINGS_DIALOG_TITLE" icon="admin_panel_settings" [canClose]="false">
            <ion-content ccIonicScrollbar class="privacy-settings-content">
                <span>{{ 'PRIVACY_SETTINGS_DIALOG_CONTENT' | translate }}</span>
                <div class="checkboxes">
                    <ng-container *ngFor="let item of items">
                        <ion-item *ngIf="item.show" lines="none">
                            <ion-checkbox
                                color="accent"
                                slot="start"
                                labelPlacement="end"
                                [formControlName]="item.control"
                            >
                                <p class="checkbox-title">
                                    {{ item.props.label | translate: { default: item.props.default } }}
                                </p>

                                <p *ngIf="item.props.sublabel" class="checkbox-message">
                                    {{ item.props.sublabel | translate }}
                                </p>
                            </ion-checkbox>
                        </ion-item>
                    </ng-container>
                </div>
            </ion-content>

            <ion-footer class="privacy-settings-footer">
                <ion-button type="submit" expand="block" data-testid="privacy-dialog-confirm">
                    {{ 'PRIVACY_SETTINGS_DIALOG_OK_BUTTON' | translate }}
                </ion-button>
            </ion-footer>
        </cc-modal>
    </form>
}
