import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiSettings } from '@shared/settings/api-settings.constant';
import { Observable } from 'rxjs';
import { TagEntity } from '../models/tag-entity.interface';

@Injectable({
    providedIn: 'root'
})
export class TagManagerApiService {
    constructor(private httpClient: HttpClient) {}

    public fetchTags(appName: string): Observable<TagEntity[]> {
        const url = ApiSettings.fullUrl(appName, '/tracking-tags/');
        return this.httpClient.get<TagEntity[]>(url);
    }
}
