<cc-modal
    [header]="showingPrivacyPolicy ? 'TOS_DIALOG_TITLE_SHOWING_PP' : 'TOS_DIALOG_TITLE_HIDING_PP'"
    [canClose]="false"
>
    <ng-container
        *ngIf="
            {
                tos: termsAndConditions$ | async,
                hasAccepted: hasAccepted$ | async
            } as tosData;
            else loading
        "
    >
        <ng-container *ngIf="!tosData.tos || tosData.hasAccepted === undefined">
            <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>

        <ng-container *ngIf="tosData.tos && tosData.hasAccepted !== undefined">
            <ion-content
                class="tos-content"
                [innerHTML]="(showingPrivacyPolicy ? tosData.tos.terms : tosData.tos.synopsis) | safe: 'html'"
            ></ion-content>

            <ion-footer class="tos-footer">
                <div class="footer-content">
                    <div *ngIf="!showingPrivacyPolicy" class="policy">
                        <span *ngIf="!tosData.hasAccepted">
                            <span>{{ 'TOS_DIALOG_CLICKING_ACCEPT' | translate }}</span>
                            <span class="view-policy" role="link" (click)="togglePrivacyPolicyVisibility()">
                                {{ 'TOS_DIALOG_PRIVACY_POLICY' | translate }}
                            </span>
                        </span>
                        <span *ngIf="tosData.hasAccepted">
                            <span>{{ 'TOS_DIALOG_VIEW_PP' | translate }}</span>
                            <span class="view-policy" role="link" (click)="togglePrivacyPolicyVisibility()">
                                {{ 'TOS_DIALOG_PRIVACY_POLICY' | translate }}
                            </span>
                        </span>
                    </div>
                    <div class="tos-button-row">
                        <ng-container *ngIf="showingPrivacyPolicy">
                            <ion-button class="tos-button" (click)="togglePrivacyPolicyVisibility()">
                                {{ 'TOS_DIALOG_BUTTON_RETURN' | translate }}
                            </ion-button>
                        </ng-container>
                        <ng-container *ngIf="!showingPrivacyPolicy">
                            <ng-container *ngIf="!tosData.hasAccepted">
                                <ion-button class="tos-button" (click)="close(false)">
                                    {{ 'TOS_DIALOG_BUTTON_DECLINE' | translate }}
                                </ion-button>
                                <ion-button class="tos-button" data-testid="tos-dialog-accept" (click)="acceptTos()">
                                    {{ 'TOS_DIALOG_BUTTON_ACCEPT' | translate }}
                                </ion-button>
                            </ng-container>
                            <ng-container *ngIf="tosData.hasAccepted">
                                <ion-button class="tos-button" (click)="close()">
                                    {{ 'TOS_DIALOG_BUTTON_CLOSE' | translate }}
                                </ion-button>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ion-footer>
        </ng-container>
    </ng-container>
    <ng-template #loading>
        <cc-loading-spinner></cc-loading-spinner>
    </ng-template>
</cc-modal>
