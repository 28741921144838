import { Inject, Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { NotificationStorageKeys } from '@core/notifications/constants/notification-storage-keys.constant';
import { StorageService } from '@core/storage/services/storage.service';
import { WINDOW } from '@core/window.service';

@Injectable({
    providedIn: 'root'
})
export class WebPushService {
    constructor(
        private storageService: StorageService,
        private swPush: SwPush,
        @Inject(WINDOW) private window: Window
    ) {}

    public getHasRejectedWebPush(appUrl: string, userId: number): boolean {
        return !!this.storageService.get(NotificationStorageKeys.hasRejectedNotifications(appUrl, userId));
    }

    public setHasRejectedWebPush(appUrl: string, userId: number): void {
        this.storageService.setStorageItem(NotificationStorageKeys.hasRejectedNotifications(appUrl, userId), true);
    }

    public isWebPushSupported(): boolean {
        return !!(this.swPush.isEnabled && 'Notification' in this.window);
    }

    public isWebPushPermissionGranted(): boolean {
        if (!this.isWebPushSupported()) {
            return false;
        }

        return Notification.permission === 'granted';
    }
}
