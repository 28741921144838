import { Injectable } from '@angular/core';
import { AppCoreFacadeService } from '@core/app-core/services/app-core-facade.service';
import { TagType } from '@core/tag-management/enums/tag-type.enum';
import { TagEntity } from '@core/tag-management/models/tag-entity.interface';
import { TagManagerApiService } from '@core/tag-management/services/tag-manager-api.service';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EnabledTags } from '../interfaces/enabled-tags.interface';

@Injectable({
    providedIn: 'root'
})
export class CookieNoticeService {
    constructor(
        private tagManagerApiService: TagManagerApiService,
        private appCoreFacadeService: AppCoreFacadeService
    ) {}

    public getEnabledTags(): Observable<EnabledTags> {
        return this.appCoreFacadeService.getAppName().pipe(
            switchMap((appName) => this.tagManagerApiService.fetchTags(appName)),
            map((tags) => this.distinctTrackers(tags))
        );
    }

    private distinctTrackers(tags): EnabledTags {
        const distinctCollection = new Set(tags.map((tag: TagEntity) => tag.tag_type));
        return {
            hotjar: distinctCollection.has(TagType.HOTJAR),
            ga: distinctCollection.has(TagType.GA),
            gtag: distinctCollection.has(TagType.GTAG)
        };
    }
}
