import { Component, Input, OnInit } from '@angular/core';
import { IonButton, IonContent, IonFooter, ModalController } from '@ionic/angular/standalone';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationFacadeService } from '@core/authentication/services/authentication-facade.service';
import { TermsAndConditions } from '../../models/terms-and-conditions.model';
import { TosApiService } from '../../services/tos-api/tos-api.service';
import { ModalComponent } from '@common/components/modal/modal.component';
import { NgIf, NgTemplateOutlet, AsyncPipe } from '@angular/common';
import { LoadingSpinnerComponent } from '@common/components/loading-spinner/loading-spinner.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '@common/pipes/safe/safe.pipe';

@Component({
    selector: 'cc-tos-dialog',
    templateUrl: './tos-dialog.component.html',
    styleUrls: ['./tos-dialog.component.scss'],
    imports: [
        ModalComponent,
        NgIf,
        NgTemplateOutlet,
        LoadingSpinnerComponent,
        AsyncPipe,
        TranslateModule,
        SafePipe,
        IonContent,
        IonFooter,
        IonButton
    ]
})
export class TosDialogComponent implements OnInit {
    @Input()
    public appUrl: string;

    public termsAndConditions$: Observable<TermsAndConditions>;
    public hasAccepted$: Observable<boolean>;

    public showingPrivacyPolicy = false;

    constructor(
        private tosApiService: TosApiService,
        private modalController: ModalController,
        private authenticationFacadeService: AuthenticationFacadeService
    ) {}

    public ngOnInit(): void {
        this.termsAndConditions$ = this.tosApiService.get(this.appUrl);
        this.hasAccepted$ = this.authenticationFacadeService
            .isAuthenticated()
            .pipe(
                switchMap((isAuthenticated) =>
                    isAuthenticated ? this.tosApiService.hasAccepted(this.appUrl) : of(true)
                )
            );
    }

    public togglePrivacyPolicyVisibility(): void {
        this.showingPrivacyPolicy = !this.showingPrivacyPolicy;
    }

    public acceptTos(): void {
        this.tosApiService.agree(this.appUrl).subscribe(() => this.close(true));
    }

    public close(data?: boolean): void {
        this.modalController.dismiss(data);
    }
}
